@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;1,900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	html {
		@apply bg-light-100 font-main text-dark-500;
	}

	* {
		-ms-overflow-style: none !important; /* Internet Explorer 10+ */
		scrollbar-width: none !important; /* Firefox */
	}

	/* Safari and Chrome */
	::-webkit-scrollbar {
		display: none !important;
		width: 0 !important;
		height: 0 !important;
	}

	body {
		margin: 0;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}

	code {
		font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
			monospace;
	}

	.without-input-controls::-webkit-inner-spin-button,
	.without-input-controls::-webkit-list-button,
	.without-input-controls::-webkit-calendar-picker-indicator,
	.without-input-controls::-webkit-outer-spin-button {
		display: none !important;
		width: 0 !important;
		height: 0 !important;
		padding: 0 !important;
		margin: 0 !important;
		appearance: none !important;
		opacity: 0 !important;
	}

	[type="text"],
	[type="email"],
	[type="url"],
	[type="password"],
	[type="number"],
	[type="date"],
	[type="datetime-local"],
	[type="month"],
	[type="search"],
	[type="tel"],
	[type="time"],
	[type="week"],
	[multiple],
	input,
	textarea,
	select {
		@apply rounded-3xl border-0 ring-0 focus:border-0 focus:ring-2 focus:ring-dark-100 focus:ring-opacity-30 focus-visible:ring-opacity-30;

		/* These don't properly work with the above */
		outline-color: rgb(32 12 61 / var(--tw-ring-opacity));
		outline-width: var(--tw-ring-offset-width);
	}

	input,
	select {
		@apply h-12;
	}

	h1 {
		@apply font-title;
	}

	td,
	th {
		@apply px-5 text-left;
	}

	td {
		@apply text-sm;
	}

	th {
		@apply px-0 text-sm font-normal;
	}

	.bg-gray-disabled {
		@apply bg-dark-100 bg-opacity-10;
	}
}

@layer components {
	/* stylelint-disable-next-line selector-class-pattern */
	div.react-datepicker__tab-loop {
		position: absolute !important;
	}

	.dashboard-title {
		@apply text-4xl font-medium;
	}
}
